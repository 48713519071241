import React, { useState, useEffect } from 'react';
import axios from "axios";
import Table from 'react-bootstrap/Table';
import { Col, Row, Divider, DatePicker, Select, Space, } from 'antd';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import loadinggg from '../../assets/img/loading-gif.gif';
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";

export default function ResultPage() {
  const [users, setUsers] = useState([]);
  const [betpandingdata, setbetpandingdata] = useState([]);
  const [pagelength, setpagelength] = useState([]);
  const [loading21, setLoading21] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [dateRanges, setDateRange] = useState([]);
  const [comxMarket, setComxMarket] = useState([]);
  const [paginate, setpaginate] = useState(1);
  const [loadbuttonshow, setloadbuttonshow] = useState();
 
 
  const navigate = useNavigate();

 

  const [selectedValue, setSelectedValue] = useState('');

  var firstNumbers = [];

  const pendingbethistory = async (tablecode) => {
    const user_id = localStorage.getItem("userid");
    if (tablecode === undefined || !tablecode) {
      tablecode = 'all';
    }
    // if (formattedDate === undefined) {
    //   var today = moment().format("DD-MM-YYYY")
    //   formattedDate = today;
    // } else {
    //   formattedDate = moment(formattedDate, "MM/DD/YYYY").format("DD-MM-YYYY");
    // }
    const pagevalue =localStorage.getItem("checkpahe");
    if(pagevalue == 1)
    {
      var abc = paginate;
    }
    else
    {
      var abc = 1;
    }
    const requestData = {
      app_id: process.env.REACT_APP_API_ID,
      user_id: user_id,
      // date: formattedDate,
      tbl_code: tablecode,
      page: abc,
      //  page: page,
      // ispaginate: 'pendingbethistory',
    };
   
    const config = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL_NODE}result-links`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: requestData,
    };
    try {
      const response = await axios(config);
      if (response.data.success === 1) {
        const userData = response.data.data;

        
            setUsers(userData);
      }
     
      
    }

    catch (error) {
      console.error('Error fetching user data:', error);
    }
  }





 

  useEffect(() => {
      pendingbethistory();
      // const pagelocal = localStorage.setItem("1");
      localStorage.setItem("checkpahe", "1");
  }, []);

  return (
    <>
      <section className="margin-bottom-88 margin-bottom-100" id='history'>
        <div className="container-fluid paddingall-5">
          <div className='historybet marketdetails'>
            <div className='padding_5'>
              <Divider orientation="center">Result Links</Divider>
              
            </div>
            <div className="bg-white p-2 round_radius">
              <div className="d-flex justify-content-between">
              

      
              
              </div>
            </div>
          </div>

          <div className="table-responsive">
            <div className="table  table-history">
              <Table striped bordered hover className=''>
                {/* <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Date</th>
                    <th>Name</th>
                  </tr>
                </thead> */}
                <tbody className=''>
                  {loading21 ? (
                    <div className="loadernew21">
                      <img src={loadinggg} className="px-2 loaderfile" alt="Loading..." />
                    </div>
                  ) : (
                    <>
                      {users && users.length > 0 ? (
                        users.map((user, index) => {
                          const dateTime = new Date(user.date_time);
                          // const formattedDate = `${dateTime.getDate()}-${dateTime.getMonth() + 1}-${dateTime.getFullYear()} ${dateTime.getHours()}:${dateTime.getMinutes()}`;
                          return (
                            <tr key={index} className={`${user.is_result_declared === 0 ? "classcolor" : "classcolor1"}`}>
                              {/* <td>{index + 1}</td> */}
                              
                              <td> <Link
                                            to={user.website}
                                            target="_blank"
                                            className='font-size_link'
                                          >
                                            {user.website}
                                          </Link></td>
                             
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="8">No data available or something went wrong.</td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </Table>
            </div>
            
            
          </div>
        </div>
      </section >
      <ToastContainer />
    </>
  )
}
